import HeaderBackButton from '../PageHeader/HeaderBackButton';
import HeaderTitle from '../PageHeader/HeaderTitle';
import CrossIcon from '../Icon/CrossIcon';
import darkTheme from '~/app/lib/theme/dark';
import Clickable from '../Clickable';
import Box from '../Box';
import { DIALOG_BOX_CONTENT_PADDING_X } from '.';

const RENDER_LEFT_PARAMS = {
  textProps: {
    color: darkTheme.textColor70,
    size: '0.85em',
  },
};

const RENDER_RIGHT_PARAMS = {
  textProps: {
    isBold: true,
    letterSpacing: 0,
    color: darkTheme.textColor80,
    size: '0.86em',
  },
};

export const DIALOG_BOX_HEADER_HEIGHT = '6.3rem';

export interface DialogBoxHeaderProps {
  renderLeft?: (params: typeof RENDER_LEFT_PARAMS) => JSX.Element;
  renderMiddle?: () => JSX.Element;
  renderRight?: (params: typeof RENDER_RIGHT_PARAMS) => JSX.Element | void;
  onBackClick?: (() => void) | false;
  onCloseClick?: () => void;
  withShadow?: boolean;
  background?: string | false;
  title?: string;
  isPadded?: boolean;
  titleColor?: string;
}

export const DialogBoxHeader = ({
  renderLeft,
  renderRight,
  renderMiddle,
  onBackClick,
  onCloseClick,
  withShadow,
  isPadded = true,
  background = '#000',
  titleColor,
  title,
}: DialogBoxHeaderProps) => {
  const renderCloseButton = (margin: string) => (
    <Clickable
      testId="closeDialog"
      onClick={onCloseClick}
      title="Close"
      margin={margin}
    >
      <CrossIcon color="#555" size="2.6rem" />
    </Clickable>
  );

  return (
    <Box
      height={DIALOG_BOX_HEADER_HEIGHT}
      padding={isPadded ? `0 ${DIALOG_BOX_CONTENT_PADDING_X}` : undefined}
      positionRelative
      fullWidth
      pointerEvents="all"
      style={{
        fontSize: '1.9rem',
        background: background ? background : undefined,
        boxShadow: withShadow ? '#000 0px 0.1rem 0.6rem' : undefined,
      }}
    >
      <Box
        positionAbsolute
        left={isPadded ? DIALOG_BOX_CONTENT_PADDING_X : 0}
        top={0}
        bottom={0}
        centerContent
      >
        {(() => {
          if (renderLeft) {
            return renderLeft(RENDER_LEFT_PARAMS);
          }

          if (onBackClick) {
            return (
              <HeaderBackButton
                onClick={onBackClick}
                size="1.1em"
                testId="dialogBackButton"
              />
            );
          }

          if (onCloseClick) {
            return renderCloseButton('-.2rem 0 0 -.6rem');
          }
        })()}
      </Box>
      {renderMiddle
        ? renderMiddle()
        : title && <HeaderTitle text={title} color={titleColor} />}
      {(renderRight || onCloseClick) && (
        <Box
          positionAbsolute
          right={isPadded ? DIALOG_BOX_CONTENT_PADDING_X : 0}
          top={0}
          bottom={0}
          centerContent
        >
          {(() => {
            if (renderRight) {
              return renderRight(RENDER_RIGHT_PARAMS);
            }
          })()}
        </Box>
      )}
    </Box>
  );
};
